import React from 'react';
import PropTypes from 'prop-types';
import { RiskBadge } from '@luxe/components';
import { getRiskCategory } from 'utils';

export default class AssetScoreDefaultValueFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
  };

  render() {
    const assetScore = this.props.value[0] || {};
    const score = assetScore.score || 1;
    return (
      <RiskBadge key={`${assetScore.threat_id}-${assetScore.id}`} variant={getRiskCategory(score)} count={score} />
    );
  }
}
